import React, { useEffect, useState } from "react";
import { Link, graphql, navigate } from "gatsby";

import LayoutMain from "../components/layout-main";
import SEO from "../components/seo";
import { Router } from "@reach/router";
import Gallary, { Menu } from "../components/gallery";

import { getImages, buildImageSet } from "../imageResource";

import Categories from "../components/gallery/categories";
import styled from "styled-components"

export const filterImages = (images) => {
  return images?.filter((item) => item.collections.includes("jackson"));
};

export const SortImages = (images) => {
  return images?.sort((b, a) =>
    a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0
  );
};

const JacksonPhotos = ({ data, location, pageContext: { renderImages, renderSelectedImage } }) => {
  const [images, setImages] = useState(filterImages(renderImages) || []);
  //const [collections, setCollections] = useState({})

  const buildCollections = () => {
    console.log("build")
    let result = {"all-photos": {title: "All Photos", images: []}};
    for (let item of images) {
      let hasCategory = false;
      for (let col of item.collections) {
        let cat = Categories.find((item) => item.id === col);
        if (cat && cat.display) {
          if (!result[cat.id]) {
            result[cat.id] = {
              title: cat.name,
              images: [item],
            };
          } else {
            result[cat.id].images.push(item);
          }
          hasCategory = true;
        }
      }

      if(!hasCategory) {
        if(!result['misc'])
        {
          result['misc'] = { title: "Miscellaneous", images: []}
        }
        result['misc'].images.push(item);
      }
      result['all-photos'].images.push(item);
    }
    return result;
  };

  /*useEffect(()=> {
    setCollections(buildCollections(images));
  }, [])*/

  let collections = buildCollections(images);

  const siteTitle = data.site.siteMetadata?.title || `Title`;

  //const images = data.allFile.edges.map((edge) => edge.node.publicURL);

  //console.log(images);

  const [selectedImage, setSelectedImage] = useState(renderSelectedImage);
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    (async () => {
      const imageResults = await getImages();
      //console.log(imageResults);
      setImages(SortImages(filterImages(buildImageSet(imageResults))));
    })();
  }, []);

  //console.log(images);

  const selectImage = (image) => {
    setSelectedImage(image);
    //window.history.pushState(null, null, `${window.location.pathname}/${image.key}`)
    //navigate(image.key);
  }

  const closeSelectedImage = () => {
    setSelectedImage(null);
    if(renderSelectedImage)
      navigate('..');
      //window.history.pushState(null, null, `..`)
  }

  console.log(location)
  
  const extraMeta = [];

  if (renderSelectedImage) {
    extraMeta.push({
      property: `og:image`,
      content: renderSelectedImage.src,
    },
    {
      property: `og:url`,
      content: `https://chrisandquynh.com/jackson/${renderSelectedImage.key}/`
    });
  }

  return (
    <LayoutMain location={location} title={siteTitle}>
      <SEO title="Jackson Photo Gallary" meta={extraMeta}/>
      {selectedCollection && (
        <>
          <Link onClick={() => setSelectedCollection(null)} to={"."}>
            Back
          </Link>
        </>
      )}
      <Gallary
        images={
          selectedCollection ? selectedCollection.images : images.slice(0, 10)
        }
        selectedImage={selectedImage}
        onImageClick={selectImage}
        onImageClose={closeSelectedImage}
      ></Gallary>
      {!selectedCollection && (
        <>
          <hr></hr>
            <Menu
              items={Object.getOwnPropertyNames(collections).map(
                (key) => collections[key]
              )}
              collectionClicked={setSelectedCollection}
            ></Menu>
        </>
      )}
    </LayoutMain>
  );
};

export default JacksonPhotos;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

/*

query ImageQuery {
  allFile(filter: {sourceInstanceName: {eq: "images"}}) {
    edges {
      node {
        publicURL
        sourceInstanceName
      }
    }
  }
}

*/
